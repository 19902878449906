.container {
    border-radius: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2.5rem;
    gap: 1rem;
}

.logo {
    width: 30rem;
}

.description {
    margin-top: 2rem;
    text-align: center;
    font-size: 1.5rem;
}

.title {
    color: white;
    font-size: 4rem;
    grid-area: title;
    text-align: left;
}

.accedi {
    color: white;
    text-decoration: none;
    text-align: center;
    background-color: #A0191D;
    border-radius: 2.5rem;
    padding: 1rem 2rem;
}

.button {
    color: white;
    text-decoration: none;
    text-align: center;
    border-radius: 2.5rem;
}

.grid-1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 1rem;
    grid-template-areas: "photo-box title";
    width: 60vw;
    min-width: 600px;
    margin: auto;
}

.photo {
    grid-area: photo;
    width: 20vw;
}

.photo-b {
    grid-area: photo;
    width: 40vw;
}

.photo-box {
    grid-area: photo-box;
    background-color: rgba(255, 255, 255, 0.747);
    border-radius: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2.5rem;
}

.title-box {
    color: #A0191D;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
    width: 100%;
}

.subtitle-box {
    color: black;
    font-size: 2.5rem;
    font-weight: bold;
    text-align: left;
}

.grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 1rem;
    grid-template-areas: "container-left container-right";
    width: 100%;
    margin: auto;
}

.container-left {
    background-color: rgba(255, 255, 255, 0.747);
    border-radius: 2.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2.5rem;
    margin: 2.5rem;
    gap: 1rem;
    grid-area: container-left;
    -webkit-box-shadow: 0 0 17px 5px rgba(0, 0, 0, 0.27);
    box-shadow: 0 0 17px 5px rgba(0, 0, 0, 0.27);
}

.container-right {
    background-color: rgba(255, 255, 255, 0.747);
    border-radius: 2.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2.5rem;
    margin: 2.5rem;
    gap: 1rem;
    grid-area: container-right;
    -webkit-box-shadow: 0 0 17px 5px rgba(0, 0, 0, 0.27);
    box-shadow: 0 0 17px 5px rgba(0, 0, 0, 0.27);
}

.grid-3 {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    grid-template-rows: 1fr;
    gap: 1rem;
    grid-template-areas: "container-left container-right";
    width: 100%;
    margin: auto;
}

@media (max-width: 500px) {
    .grid-1 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 1rem;
        grid-template-areas: "photo-box" "title";
        width: 100vw;
        margin: auto;
    }
    .grid-2 {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 1rem;
        grid-template-areas: "container-left" "container-right";
        width: 100%;
        margin: auto;
    }
    .grid-3 {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 1rem;
        grid-template-areas: "container-left" "container-right";
        width: 100vw;
        margin: auto;
    }
    .photo-box {
        grid-area: photo-box;
        background-color: rgba(255, 255, 255, 0.747);
        border-radius: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 2.5rem;
    }
    .title-box {
        color: #A0191D;
        font-size: 1rem;
        font-weight: bold;
        text-align: left;
        width: 100%;
    }
    .subtitle-box {
        color: black;
        font-size: 2rem;
        font-weight: bold;
        text-align: left;
    }
    .photo {
        grid-area: photo;
        width: 100%;
    }
    .photo-b {
        grid-area: photo;
        width: 100%;
    }
}
