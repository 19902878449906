h1 {
    text-align: center;
    font-family: arial, sans-serif;
}

.modifica {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    cursor: pointer;
}

.elimina {
    margin-left: 5px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    cursor: pointer;
}

#new-zone {
    margin-top: 10px;
    line-height: 30px;
    font-size: 18px;
}
