@import "https://use.fontawesome.com/releases/v5.5.0/css/all.css";

.img-container {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-size: cover;
}

.background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;;
    object-fit: cover;
}

.login-box {
    width: 280px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}

.login-box h1 {
    float: left;
    font-size: 40px;
    border-bottom: 6px solid var(--primary);
    margin-bottom: 50px;
    padding: 13px 0;
}

.textbox {
    width: 100%;
    overflow: hidden;
    font-size: 20px;
    padding: 8px 0;
    margin: 8px 0;
}

.textbox i {
    float: left;
    text-align: center;
    margin-top: 8px;
}

.textbox input {
    border: none;
    outline: none;
    background: none;
    color: white;
    font-size: 18px;
    width: 80%;
    float: left;
    margin: 0 10px;
}

.btn {
    width: 100%;
    background: none;
    border: 2px solid var(--primary-dark);
    background: var(--primary);
    color: white;
    padding: 5px;
    font-size: 18px;
    cursor: pointer;
    margin: 12px 0;
}
.btn:hover:enabled {
    background: var(--primary-dark);
}
