@import url(https://use.fontawesome.com/releases/v5.5.0/css/all.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-container {
    background-image: url(/static/media/vigna.e35091e6.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.bg-container-2 {
    position: absolute;
    width: 100vw;
    height: 100vh;
}

:root {
    --primary: rgba(160, 25, 29, 255);
    --primary-dark: rgb(101, 16, 18);
}

table {
  background-color:rgb(220, 240, 260);
  text-align: left;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border: 2px solid black;
  border-collapse: collapse;
}

th {
  background: rgba(160, 25, 29, 255);
  color: white;
  text-align: left;
  font-size: 18px;
  padding: 8px;
}

td {
  padding: 6px;
}

th, td {
  border: 2px solid black;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #ddd;
}

#welcome {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

#logout-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  line-height: 30px;
  width: 100px;
  font-size: 18px;
}

form {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}

input {
  margin-top: 10px;
  border-radius: 5px;
  font-size: 18px;
  width: 500px;
  padding: 8px;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}

button {
  border-radius: 5px;
  font-size: 18px;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  padding: 10px;
}
button:hover:enabled {
  background-color: rgb(172, 172, 172);
  cursor: pointer;
}

.m-t-10 {
  margin-top: 10px;
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1 {
    text-align: center;
    color:rgba(160, 25, 29, 255);
    font-family: arial, sans-serif;
    font-weight: bold;
}

.modifica {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    cursor: pointer;
}

.elimina {
    margin-left: 5px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    cursor: pointer;
}

#new-winery {
    margin-top: 10px;
    line-height: 30px;
    font-size: 18px;
}


.img-container {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-size: cover;
}

.background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;;
    object-fit: cover;
}

.login-box {
    width: 280px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}

.login-box h1 {
    float: left;
    font-size: 40px;
    border-bottom: 6px solid var(--primary);
    margin-bottom: 50px;
    padding: 13px 0;
}

.textbox {
    width: 100%;
    overflow: hidden;
    font-size: 20px;
    padding: 8px 0;
    margin: 8px 0;
}

.textbox i {
    float: left;
    text-align: center;
    margin-top: 8px;
}

.textbox input {
    border: none;
    outline: none;
    background: none;
    color: white;
    font-size: 18px;
    width: 80%;
    float: left;
    margin: 0 10px;
}

.btn {
    width: 100%;
    background: none;
    border: 2px solid var(--primary-dark);
    background: var(--primary);
    color: white;
    padding: 5px;
    font-size: 18px;
    cursor: pointer;
    margin: 12px 0;
}
.btn:hover:enabled {
    background: var(--primary-dark);
}

.container {
    border-radius: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2.5rem;
    grid-gap: 1rem;
    gap: 1rem;
}

.logo {
    width: 30rem;
}

.description {
    margin-top: 2rem;
    text-align: center;
    font-size: 1.5rem;
}

.title {
    color: white;
    font-size: 4rem;
    grid-area: title;
    text-align: left;
}

.accedi {
    color: white;
    text-decoration: none;
    text-align: center;
    background-color: #A0191D;
    border-radius: 2.5rem;
    padding: 1rem 2rem;
}

.button {
    color: white;
    text-decoration: none;
    text-align: center;
    border-radius: 2.5rem;
}

.grid-1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 1rem;
    gap: 1rem;
    grid-template-areas: "photo-box title";
    width: 60vw;
    min-width: 600px;
    margin: auto;
}

.photo {
    grid-area: photo;
    width: 20vw;
}

.photo-b {
    grid-area: photo;
    width: 40vw;
}

.photo-box {
    grid-area: photo-box;
    background-color: rgba(255, 255, 255, 0.747);
    border-radius: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2.5rem;
}

.title-box {
    color: #A0191D;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
    width: 100%;
}

.subtitle-box {
    color: black;
    font-size: 2.5rem;
    font-weight: bold;
    text-align: left;
}

.grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 1rem;
    gap: 1rem;
    grid-template-areas: "container-left container-right";
    width: 100%;
    margin: auto;
}

.container-left {
    background-color: rgba(255, 255, 255, 0.747);
    border-radius: 2.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2.5rem;
    margin: 2.5rem;
    grid-gap: 1rem;
    gap: 1rem;
    grid-area: container-left;
    box-shadow: 0 0 17px 5px rgba(0, 0, 0, 0.27);
}

.container-right {
    background-color: rgba(255, 255, 255, 0.747);
    border-radius: 2.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2.5rem;
    margin: 2.5rem;
    grid-gap: 1rem;
    gap: 1rem;
    grid-area: container-right;
    box-shadow: 0 0 17px 5px rgba(0, 0, 0, 0.27);
}

.grid-3 {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    grid-template-rows: 1fr;
    grid-gap: 1rem;
    gap: 1rem;
    grid-template-areas: "container-left container-right";
    width: 100%;
    margin: auto;
}

@media (max-width: 500px) {
    .grid-1 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-gap: 1rem;
        gap: 1rem;
        grid-template-areas: "photo-box" "title";
        width: 100vw;
        margin: auto;
    }
    .grid-2 {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 1rem;
        gap: 1rem;
        grid-template-areas: "container-left" "container-right";
        width: 100%;
        margin: auto;
    }
    .grid-3 {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 1rem;
        gap: 1rem;
        grid-template-areas: "container-left" "container-right";
        width: 100vw;
        margin: auto;
    }
    .photo-box {
        grid-area: photo-box;
        background-color: rgba(255, 255, 255, 0.747);
        border-radius: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 2.5rem;
    }
    .title-box {
        color: #A0191D;
        font-size: 1rem;
        font-weight: bold;
        text-align: left;
        width: 100%;
    }
    .subtitle-box {
        color: black;
        font-size: 2rem;
        font-weight: bold;
        text-align: left;
    }
    .photo {
        grid-area: photo;
        width: 100%;
    }
    .photo-b {
        grid-area: photo;
        width: 100%;
    }
}

h1 {
    text-align: center;
    font-family: arial, sans-serif;
}

.modifica {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    cursor: pointer;
}

.elimina {
    margin-left: 5px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    cursor: pointer;
}

#new-zone {
    margin-top: 10px;
    line-height: 30px;
    font-size: 18px;
}

h1 {
    text-align: center;
    color: rgba(160, 25, 29, 255);
    font-family: arial, sans-serif;
    font-weight: bold;
}

#modifica {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
}

.elimina {
    margin-left: 5px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
    display: inline-block;
}

table {
    background-color: rgb(220, 240, 260);
    text-align: left;
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    border: 2px solid black;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

select {
    padding: 8px;
    margin-top: 10px;
    border-radius: 5px;
    font-size: 18px;
    width: 520px;
    background: white;
    border: 2px solid black;
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}

input[type="checkbox"] {
    height: 20px;
    -webkit-filter: hue-rotate(140deg);
            filter: hue-rotate(140deg);
    width: 20px;
}

label {
    font-size: 18px;
    margin-left: 10px;
}

