body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-container {
    background-image: url("assets/vigna.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.bg-container-2 {
    position: absolute;
    width: 100vw;
    height: 100vh;
}

:root {
    --primary: rgba(160, 25, 29, 255);
    --primary-dark: rgb(101, 16, 18);
}

table {
  background-color:rgb(220, 240, 260);
  text-align: left;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border: 2px solid black;
  border-collapse: collapse;
}

th {
  background: rgba(160, 25, 29, 255);
  color: white;
  text-align: left;
  font-size: 18px;
  padding: 8px;
}

td {
  padding: 6px;
}

th, td {
  border: 2px solid black;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #ddd;
}

#welcome {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

#logout-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  line-height: 30px;
  width: 100px;
  font-size: 18px;
}

form {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}

input {
  margin-top: 10px;
  border-radius: 5px;
  font-size: 18px;
  width: 500px;
  padding: 8px;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}

button {
  border-radius: 5px;
  font-size: 18px;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  padding: 10px;
}
button:hover:enabled {
  background-color: rgb(172, 172, 172);
  cursor: pointer;
}

.m-t-10 {
  margin-top: 10px;
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
