h1 {
    text-align: center;
    color: rgba(160, 25, 29, 255);
    font-family: arial, sans-serif;
    font-weight: bold;
}

#modifica {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
}

.elimina {
    margin-left: 5px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
    display: inline-block;
}

table {
    background-color: rgb(220, 240, 260);
    text-align: left;
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    border: 2px solid black;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

select {
    padding: 8px;
    margin-top: 10px;
    border-radius: 5px;
    font-size: 18px;
    width: 520px;
    background: white;
    border: 2px solid black;
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}

input[type="checkbox"] {
    height: 20px;
    filter: hue-rotate(140deg);
    width: 20px;
}

label {
    font-size: 18px;
    margin-left: 10px;
}
